'use client';
import { SignIn } from '@clerk/nextjs';
import { Container } from '@mantine/core';
import { useMemo } from 'react';
import { clientConfig } from '~/core/config/config';
import { useQueryParams } from '~/ui/hooks/app.router.hook';
import { useClerk } from '~/ui/hooks/clerk.hook';

const LoginPage: React.FC = () => {
  const { appearance } = useClerk();
  const {
    params: { redirectAfter },
  } = useQueryParams<{ redirectAfter: string }>();
  const fallbackRedirectUrl = useMemo(() => {
    if (redirectAfter) {
      return `${clientConfig.origin}${redirectAfter}`;
    }
    return `${clientConfig.origin}/cards`;
  }, [redirectAfter]);

  return (
    <Container size={'xs'} mt={'xl'}>
      <SignIn
        appearance={appearance}
        signUpUrl={`${clientConfig.origin}/signup`}
        fallbackRedirectUrl={fallbackRedirectUrl}
        signUpFallbackRedirectUrl={`${clientConfig.origin}/welcome`}
      />
    </Container>
  );
};

export default LoginPage;
